<template>
  <list-visit-additional :kind="'facultative'" />
</template>
<script>
import ListVisitAdditional from './ListVisitAdditional.vue'

export default {
    name: 'ListFacultativeSections',
    components: {
        ListVisitAdditional,
    },
}
</script>
